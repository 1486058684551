<template>
  <div>
    <Headers title="温州市服装标准化创新服务平台" :show="false" :returnShow="true"></Headers>
    <div class="content">
      <ul class="tab-ul">
        <li class="tab-li" v-for="(item,index) in list" :key="index" :class="{'active':tabindex==index}" @click="tabindex=index">{{item}}</li>
      </ul>
      <el-table :data="tableData" style="width: 100%" @row-click="handleRowClick">
        <el-table-column prop="IndustryName" label="标准号" align="left" width="240" show-overflow-tooltip tooltip-effect="dark"></el-table-column>
        <el-table-column prop="NotifyTitle" label="标准名称" align="left" show-overflow-tooltip tooltip-effect="dark"></el-table-column>
        <el-table-column prop="Member" label="标准状态" align="center" width="140" show-overflow-tooltip tooltip-effect="dark"></el-table-column>
        <el-table-column prop="NotifyDateFormat" label="代替标准" align="center" width="140"></el-table-column>
        <el-table-column prop="RiskLevel" align="center" label="变动内容" width="120"></el-table-column>
        <el-table-column align="center" label="涉变标准" width="120"></el-table-column>
      </el-table>
      <div class="pager">
        <el-pagination background layout="prev, pager, next" :current-page.sync="PageIndex" :total="total" @current-change="changepage" :page-size="PageSize">
        </el-pagination>
      </div>
    </div>

<!-- 服务矩阵——监管部门 -->
<!-- 标准号
标准名称
标准状态
涉联标准
涉联数据
涉联企业数
涉联机构数
推送部门 -->


<!-- 服务矩阵-企业 -->
<!-- 企业名称
属地
执行标准
执行标准名称
涉联标准
涉联标准
状态
涉变数据
对策建议 -->


<!-- 第三方机构-二级页面 -->
<!-- 机构名称
属性
检测对象
检测能力
执行标准
涉联标准
涉变数据
对策建议 -->

    <!-- <el-dialog custom-class="tcbg" :visible.sync="dialogVisible" :before-close="handleClose" top="30vh" width="50%">
      <span style="color:#333;font-size:24px;">{{dialogcont}}</span>
    </el-dialog> -->
  </div>
</template>
<script>
import Headers from "@/components/headers"

export default {
  name: "TbtList",
  components: {
    Headers
  },
  data () {
    return {
      dialogVisible: false,
      dialogcont: "",
      PageIndex: 1,
      PageSize: 10,
      total: 100,
      code: "Qqyjslbpage",
      rate: 'RED',
      dataType: "",
      tableData: [],
      list:['国家标准','行业标准','地方标准','团体标准'],
      tabindex:0,
    }
  },
  async mounted () {
    if (this.$route.query.rate) {
      this.rate = this.$route.query.rate
    }

    this.dataType = this.$route.query.dataType || ""
    await this.getFacors()
    await this.init()
  },
  methods: {
    dateFormat (data) {
      return data.NotifyDate ? this.$moment(data.NotifyDate).format('YYYY-MM-DD') : ''
    },
    getTabType (e) {
      if(this.$route.query.rate){
        return this.items[this.rate].color
      } else {
        if(e==='红色'){
          return this.items['RED'].color
        } else if(e==='黄色'){
          return this.items['YELLOW'].color
        } else if(e==='蓝色'){
          return this.items['BLUE'].color
        }
      }
    },
    changepage (e) {
      this.PageIndex = e
      this.init()
    },
    async init () {
      const res = await this.$axios({
        url: `${this.$apiUrl}common/interface`,
        method: 'post',
        data: { pageNum: this.PageIndex, pageSize: this.PageSize, code: this.code, rate: this.$route.query.rate, dataType: this.dataType }
      })

      var list = res.data.data.list
      this.total = res.data.data.total
      if (list.length > 0) {
        this.tableData = list
      }
    },
    computeIndex (index) {
      return (index + 1) + (this.PageIndex - 1) * this.PageSize
    },
    handleRowClick (row) {
      console.log(row)
      window.open('/tbdetails?tbtID=' + row.TbtID)
    },
    handleClose () {
      this.dialogVisible = false
      this.dialogcont = ""
    },
  }
};
</script>
<style lang="less" scoped>
.content {
  width: 100%;
  height: 1000px;
  padding: 20px 50px;
}

.pager {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
}

/deep/ .el-table__header tr {
  background: none !important;
}

/deep/ .el-table th.el-table__cell {
  background-color: transparent !important;
  color: #cbe8ff;
  font-size: 24px;
}

/deep/ .el-table th.el-table__cell > .cell {
  line-height: 48px !important;
}
// /deep/ .el-table .cell{
//     font-size: 18px;
// }
/deep/ .el-table__header tr {
  background-color: transparent;
}

/deep/ .el-table,
.el-table__expanded-cell {
  color: #fff;
  background-color: transparent;
}

/deep/ .el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border-color: rgb(128, 128, 128);
}

/deep/ .el-table tr {
  background-color: transparent !important;
  //   background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAhIAAAAhCAYAAACMTEKVAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA3xpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDowZmQ5OGZlMy1hOWZkLTAxNGItYjk2OS1lN2Q1NzRiM2M3MGQiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6RDNEMjA0NDFDNDIxMTFFQzk2NTBFRTFEMzNCN0U2MzAiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6RDNEMjA0NDBDNDIxMTFFQzk2NTBFRTFEMzNCN0U2MzAiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo3NDVFRTI5QjE2QzRFQzExOUI4MkRGMkRERjhBNUI1RSIgc3RSZWY6ZG9jdW1lbnRJRD0iYWRvYmU6ZG9jaWQ6cGhvdG9zaG9wOmI4YTMxNGQ0LWRiZjEtNDI0Yi1hMTg5LTdhOTNkZjE1YThhMyIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PmrImdMAAACYSURBVHja7NahEQAgDATBH4Yu0n9RVBMEEhW/W8Kp2zndeSoAAANLAgDASAAARgIAMBIAgJEAADASAICRAACMBABgJAAAIwEAYCQAACMBABgJAMBIAAAYCQDASAAARgIAMBIAgJEAADASAICRAACMBABgJAAAjAQAYCQAACMBABgJAMBIAAAYCQDASAAARgIAMBIAAJ8rwADy9AM3qzlP+gAAAABJRU5ErkJggg==);
  //   background-size: 100% 90%;
  //   background-repeat: no-repeat;
  padding: 0 10px;
  box-sizing: border-box;
}

/deep/ .el-table--enable-row-transition .el-table__body td,
.el-table .cell {
  background-color: transparent;
}

/deep/ .el-table::before {
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0px;
  background: transparent;
}

/deep/.el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: #155488 !important;
  cursor: pointer;
}
/deep/ .el-table__header .cell {
  text-align: center;
}
.tab-ul{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tab-li{
  width: 200px;
  height: 50px;
  color: #fff;
  font-size: 24px;
  background: #61b2c5;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  cursor: pointer;
}
.tab-li:last-child{
  margin-right: 0;
}
.tab-li.active{
  font-weight: bold;
}
</style>
