<template>
  <div>
    <Headers title="温州市服装标准化创新服务平台" :show="false" :returnShow="true"></Headers>
    <div class="content">
      <div style="display: flex;width: 100%;">
        <div class="fxbg1">
          <div class="fxbg-t">
            <span class="fxbg-tl">{{company.OrgName}}：
              <!-- <em class="hrefs dp-a" @click="openu(`https://bz.zjamr.zj.gov.cn/front/007351.html?uscc=${company.USCC}&dnFromType=2&name=`+encodeURIComponent(company.OrgName))"></em> -->
            </span>
          </div>
          <div class="fxbg-tb" v-html="company.ReportInfo">
          </div>
        </div>
      </div>
      <div style="display: flex;width: 100%;justify-content: space-between;">
        <div class="fxbg4">
          <span class="fxbg-title">标准信息</span>
          <ul class="ld-2-list">
            <li>
                <div class="ld-2-m">标准号：<span class="ld-2-listnr">{{company.StdNO}}</span></div>
                <div class="ld-2-m">标准状态：<span class="ld-2-listnr">{{getStatus()}}</span></div>
            </li>
            <span class="ld-2-listline"><img src="../../../src/assets/imgs/ld-2-listline.png" alt=""></span>
            <!-- <li></li>
            <span class="ld-2-listline"><img src="../../../src/assets/imgs/ld-2-listline.png" alt=""></span> -->
            <li>标准名称：<span class="ld-2-listnr">{{company.CnName}}</span></li>
            <span class="ld-2-listline"><img src="../../../src/assets/imgs/ld-2-listline.png" alt=""></span>
            <li>
                <div class="ld-2-m">发布日期：<span class="ld-2-listnr">{{company.PubDate}}</span></div>
                <div class="ld-2-m">实施日期：<span class="ld-2-listnr">{{company.ActDate}}</span></div>
                <div class="ld-2-m">作废日期：<span class="ld-2-listnr">{{company.EndDate}}</span></div>
            </li>
            <!-- <span class="ld-2-listline"><img src="../../../src/assets/imgs/ld-2-listline.png" alt=""></span>
            <li><div>实施日期：<span class="ld-2-listnr">{{company.ActDate}}</span></div></li>
            <span class="ld-2-listline"><img src="../../../src/assets/imgs/ld-2-listline.png" alt=""></span>
            <li><div>作废日期：<span class="ld-2-listnr">{{company.EndDate}}</span></div></li> -->
            <span class="ld-2-listline"><img src="../../../src/assets/imgs/ld-2-listline.png" alt=""></span>
            <li><span class="ld-2-t">作废依据：</span><span class="ld-2-listnr">{{company.StdOrigin}}</span></li>
            <span class="ld-2-listline"><img src="../../../src/assets/imgs/ld-2-listline.png" alt=""></span>
            <li><span class="ld-2-t">标准范围：</span><span class="ld-2-listnr">{{company.StdBound}}</span></li>
            <span class="ld-2-listline"><img src="../../../src/assets/imgs/ld-2-listline.png" alt=""></span>
            <!-- <li>替代标准：<span class="ld-2-listnr">{{company.InsteadStd}}</span></li> -->
          </ul>
        </div>
        <!-- <div class="fxbg6">
          <span class="fxbg-title">主要变化</span>
          <div class="ld-1-3p">
            <p v-html="company.ForewordChange"></p>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import Headers from "@/components/headers"

export default {
  name: "table3",
  components: {
    Headers,
  },
  data () {
    return {
      // 以上都是老数据
      company: {},
    }
  },
  created () {
    this.init()
  },
  methods: {
    openu (url) {
      window.open(url)
    },
    async init () {
      this.data = {}
      const res = await this.$axios({
        url: `${this.$apiUrl}common/interface`,
        method: 'post',
        data: { "code": "getThirdOrgInfo", id: this.$route.query.id, single: true }
      })

      console.log(res)

      this.company = res.data.data
    },
    getStatus () {
      const val = Number(this.company.StdStatus)
      if (val == 1) {
        return '现行'
      } else if (val == 2) {
        return '未实施'
      } else if (val == 3) {
        return '作废'
      } else {
        return ''
      }
    },
    getElementsType () {
      let arr = []
      if (this.company.ElementsType1) {
        arr.push(this.company.ElementsType1)
      }
      if (this.company.ElementsType2) {
        arr.push(this.company.ElementsType2)
      }
      if (this.company.ElementsType3) {
        arr.push(this.company.ElementsType3)
      }
      if (this.company.ElementsType4) {
        arr.push(this.company.ElementsType4)
      }
      return arr.join('、')
    },
    getAdvice () {
      let arr = []
      if (this.company.AdviceInfo1) {
        arr.push(this.company.AdviceInfo1)
      }
      if (this.company.AdviceInfo2) {
        arr.push(this.company.AdviceInfo2)
      }
      return arr.join('，')
    }
  },
};
</script>
<style lang="less" scoped>
.content {
  width: 100%;
  height: 1000px;
  display: flex;
  //   justify-content: space-between;
  flex-wrap: wrap;
  padding: 20px 275px;
}

.fxbg1 {
  // width: 443px;
  // height: 460px;
  width: 100%;
  height: 260px;
  background-image: url(../../assets/img/by-t12.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
  padding: 20px;
}
.fxbg-t {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.fxbg-tl {
  color: #fff;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}
.fxbg-tr {
  color: #ffae00;
  font-size: 24px;
  font-weight: bold;
}
.fxbg-tb {
  color: #fff;
  font-size: 24px;
  text-indent: 2em;
  line-height: 36px;
}
.fxbg-tb em {
  font-size: 24px;
  color: #00deff;
  font-style: normal;
}
.fxbg-ul {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 60px 20px 20px 20px;
}

.fxbg-li {
  width: 100%;
  height: 38px;
  line-height: 38px;
  /*background-image: url(../../assets/img/by-t13.png);*/
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
  padding: 0 10px;
  margin-bottom: 10px;
}

.fxbg-li span {
  color: #00ffde;
  font-weight: bold;
  font-size: 18px;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.fxbg-li span em {
  font-style: normal;
  color: #fff;
  font-weight: normal;
  font-size: 18px;
}

.fxbg3 {
  width: 423px;
  height: 460px;
  /*background-image: url(../../assets/img/by-t14.png);*/
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 60px 20px 20px 20px;
  font-size: 20px;
}

.fxbg4 {
  // width: 502px;
  // height: 460px;
  width: 100%;
  height: 550px;
  background-image: url(../../assets/img/by-t19.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
  // padding: 30px 0 0 0;
  position: relative;
}

.fxbg5 {
  width: 100%;
  height: 320px;
  /*background-image: url(../../assets/img/by-t16.png);*/
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
  padding: 60px 20px 20px 20px;
}

.fxbg6 {
  // width: 100%;
  // height: 238px;
  width: 851px;
  height: 550px;
  padding: 0 20px 20px 20px;
  background-image: url(../../assets/img/radar-l.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
}

.ld-1-3p {
  width: 100%;
  height: calc(100% - 40px);
  padding: 10px;
}
.ld-1-3p p {
  width: 100%;
  height: 100%;
  color: #00fff0;
  font-size: 22px;
  line-height: 36px;
  text-align: justify;
  white-space: pre-wrap;
  overflow: hidden;
  overflow-y: auto;
  padding-right: 10px;
}
.fxbg3-l {
  width: 100%;
  // height: 50px;
  /*background-image: url(../../assets/img/by-t17.png);*/
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  align-items: center;
  padding: 15px;
  margin-bottom: 5px;
}

.fxbg3-b {
  width: 100%;
  height: 150px;
  /*background-image: url(../../assets/img/by-t17.png);*/
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
  padding: 20px;
}

.fxbg3-l span {
  color: #00ffde;
}

.fxbg3-l span em {
  font-style: normal;
  color: #fff;
}

.fxbg3-b span {
  color: #00ffde;
  line-height: 28px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.fxbg3-b span em {
  font-style: normal;
  color: #fff;
}

.fxbg5 p {
  line-height: 34px;
  color: #fff;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  padding: 0 30px;
  font-size: 22px;
}

.fxbg2 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  // top: 50%;
  // left: 50%;
  // margin-left: -80px;
  // margin-top: -61px;
  // background: rgba(255,255,255,0.5);
  background: url(../../assets/img/radar.png) no-repeat center center;
  // border-radius: 50%;
}

.fxbg2 span:first-child {
  font-weight: bold;
  font-size: 60px;
  // color: #00ffde;
}

.fxbg2 span:last-child {
  font-weight: bold;
  font-size: 28px;
  // color: #00ffde;
}

.by-a {
  color: #fff;
}
.by-zwc {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.by-zw {
  border: 1px solid #00deff;
  padding: 5px 10px;
  border-radius: 2px;
  color: #00deff;
  margin-left: 10px;
}
.by-zw:first-child {
  margin-left: 0;
}
.fxbg-deng {
  position: absolute;
  right: 15px;
  top: 25px;
}
.fxbg-deng img {
  width: 70px;
}
.fxbg-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.fxbg-btn span {
  padding: 15px;
  color: #fff;
  background: rgba(0, 222, 255, 0.6);
  border-radius: 5px;
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
}
.ld-2-list {
  // margin-left: 20px;
  // margin-top: 56px;
  padding: 20px;
}

.ld-2-list li {
  font-size: 22px;
  line-height: 36px;
  color: white;
  display: flex;
}
.ld-2-listnr {
  color: #00ffde;
}
.fxbg-title {
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 100%;
  font-size: 20px;
  font-weight: bold;
}
.ld-2-t{
    min-width: 110px;
}
.ld-2-m{
    width:400px;
    margin-right: 50px;
}
.hrefs{
  display: inline-block;
}
</style>
