<template>
  <div>
    <Headers title="温州市服装标准化创新服务平台" :show="false" :returnShow="true"></Headers>
    <div class="content">
      <div class="tb-l">
        <div class="tb-ll" style="justify-content: space-between;">
          <div>
            <span><em>标准号：</em>{{dataInfo.StdNO}}</span>
          </div>
          <div>
            <span><em>标准状态：</em>{{getStdState(dataInfo.StdStatus)}}</span>
          </div>
        </div>
        <div class="tb-ll">
          <span><em>中文名称：</em>{{dataInfo.CnName}}</span>
        </div>
        <div class="tb-ll">
          <span><em>英文名称：</em>{{dataInfo.EnName}}</span>
        </div>
        <div class="tb-ll" style="justify-content: space-between;">
          <div>
            <span><em>中标分类：</em>{{dataInfo.CnClass}}</span>
          </div>
          <div>
            <span><em>ICS分类：</em>{{dataInfo.IcsClass}}</span>
          </div>
        </div>
        <div class="tb-ll" style="justify-content: space-between;">
          <div>
            <span><em>发布日期：</em>{{dataInfo.PubDate}}</span>
          </div>
          <div>
            <span><em>实施日期：</em>{{dataInfo.ActDate}}</span>
          </div>
          <div>
            <span><em>作废日期：</em>{{dataInfo.IcsClass}}</span>
          </div>
        </div>
        <div class="tb-ll">
          <span><em>替代标准：</em>{{dataInfo.InsteadStd}}</span>
        </div>
        <div class="tb-ll tb-ll3">
          <span><em>被替代标准：</em>{{dataInfo.ReplacedStd}}</span>
        </div>
        <div class="tb-ll">
          <span><em>关键词：</em>{{dataInfo.CnKeyword}}</span>
        </div>
        <div class="tb-ll tb-ll3">
          <span><em>范围：</em>{{dataInfo.StdBound}}</span>
        </div>
      </div>
      <!-- <div class="tb-r">
        <div class="tb-rc">
          <div class="dp-wu" v-if="!src">暂无文本</div>
          <iframe ref="frame" class="dp-iframe" v-if="src" :src="src" frameborder="0"></iframe>
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
import Headers from "@/components/headers"

export default {
  name: "CatalogView",
  components: {
    Headers
  },
  data () {
    return {
      dataInfo: {},
      aActive: 'active',
      bActive: '',
      src: ''
    }
  },
  mounted () {
    this.getdata()
  },
  methods: {
    async getdata () {
      const res = await this.$axios.post(`${this.$apiUrl}common/interface`, { code: 'getCatalogInfo', id: this.$route.query.id, single: true })
      this.dataInfo = res.data.data
    },
    getStdState (state) {
      const val = Number(state)
      if (val == 1) {
        return '现行'
      } else if (val == 2) {
        return '未实施'
      } else if (val == 3) {
        return '作废'
      } else {
        return ''
      }
    },
    change (val) {
      if (val == 1) {
        this.src = this.dataInfo.CnPdfUrl
        this.aActive = 'active'
        this.bActive = ''
      } else {
        this.src = this.dataInfo.EnPdfUrl
        this.aActive = ''
        this.bActive = 'active'
      }
    }
  },
};
</script>
<style lang="less" scoped>
.content {
  width: 100%;
  height: 1000px;
  display: flex;
  justify-content: space-between;
  padding: 20px 90px;
}
.tb-l {
  width: 100%;
}
.tb-r {
  width: 665px;
}
.tb-ll {
  width: 100%;
  min-height: 75px;
  background-image: url(../../assets/img/by-t2.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 10px 70px;
  margin-bottom: 20px;
  word-break: break-all;
}
.tb-ll2 span {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.tb-ll3 span {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
}
.tb-ll span em {
  font-style: normal;
  color: #fff;
}
.tb-ll span {
  font-size: 24px;
}
.tb-ll span:first-child {
  color: #fff;
}
.tb-ll span:last-child {
  color: #00deff;
}
.tb-rt {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 20px;
}
.tb-rt span {
  color: #00deff;
  border: 2px solid #00deff;
  padding: 5px 20px;
  border-radius: 3px;
  margin-left: 20px;
  cursor: pointer;
  font-size: 24px;
}
.tb-rt span.active {
  background: #00deff;
  color: #fff;
}
.tb-rc {
  width: 100%;
  height: calc(100% - 60px);
  background-image: url(../../assets/img/by-t3.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
  padding: 30px 40px;
}
.dp-wu {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  font-size: 40px;
  color: #ccc;
}
.dp-iframe {
  width: 100%;
  height: 100%;
}
</style>
