<template>
  <div class="demo-image__placeholder" style="height:100%">
    <dv-loading v-if="loading">Loading...</dv-loading>
    <el-image :src="src" style="height:100%;width:100%" fit="fill">
      <div slot="placeholder" class="image-slot">
        加载中<span class="dot">...</span>
      </div>
    </el-image>
    <div class="return" @click="close()"></div>

  </div>
</template>
<script>
export default {
  name: "imageView",
  data() {
    return {
      src: "",
      loading: false,
    };
  },
  created() {
    this.$nextTick(() => {
      this.src = require(`../assets/img/${this.$route.query.url}.png`);
    });
  },
  methods: {
    close() {
      window.location.href = "about:blank";
      window.close();
    },
  },
};
</script>
<style lang="less" scoped>
.return {
  background-image: url(../assets/img/return.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: absolute;
  width: 100px;
  height: 35px;
  margin-left: 3vh;
  cursor: pointer;
  z-index: 999999999;
  top: 50px;
}
</style>