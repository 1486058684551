<template>
  <div>
    <Headers :title="title" :show="false" :returnShow="true"></Headers>
    <div class="content">
      <el-table :data="tableData" style="width: 100%" @row-click="handleRowClick">
        <el-table-column prop="OrgName" label="机构名称" align="left" width="240" show-overflow-tooltip tooltip-effect="dark"></el-table-column>
        <el-table-column prop="Category" label="检测对象" align="left" show-overflow-tooltip tooltip-effect="dark"></el-table-column>
        <el-table-column prop="ProductName" label="检测能力" align="left" show-overflow-tooltip tooltip-effect="dark"></el-table-column>
        <el-table-column prop="Standard" label="执行标准" align="left" show-overflow-tooltip tooltip-effect="dark"></el-table-column>
        <el-table-column prop="RelationStd" label="涉联标准" align="left" show-overflow-tooltip tooltip-effect="dark"></el-table-column>
        <el-table-column prop="" label="涉变数据" width="350" show-overflow-tooltip tooltip-effect="dark">
          <template slot-scope="scope">
            <span>{{getElementsType(scope.row)}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="" label="对策建议" width="130" align="center">
          <template>
            <span class="dp-see"></span>
          </template>
        </el-table-column>
      </el-table>
      <div class="pager">
        <el-pagination background layout="prev, pager, next" :current-page.sync="PageIndex" :total="total" @current-change="changepage" :page-size="PageSize">
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import Headers from "@/components/headers"

export default {
  name: "Org",
  components: {
    Headers
  },
  data () {
    return {
      dialogVisible: false,
      dialogcont: "",
      PageIndex: 1,
      PageSize: 12,
      total: 100,
      code: "listThirdOrg",
      tableData: [],
      type:"",
      title:"温州市服装标准化创新服务平台"
    }
  },
  mounted () {
    this.init()
    if(this.$route.query.type){
      if(this.$route.query.type=='NL'){
        this.title="第三方机构-能力取消"
      }else{
        this.title="第三方机构-资质变更"
      }
    }
  },
  methods: {
    dateFormat (data) {
      return data.NotifyDate ? this.$moment(data.NotifyDate).format('YYYY-MM-DD') : ''
    },
    changepage (e) {
      this.PageIndex = e
      this.init()
    },
    async init () {
      let data = { pageNum: this.PageIndex, pageSize: this.PageSize, code: this.code }
      if (this.$route.query.std) {
        data.std = this.$route.query.std
      }
      if (this.$route.query.rstd) {
        data.rstd = this.$route.query.rstd
      }
      
      if (this.$route.query.type) {
        data.type = this.$route.query.type
      }
      const res = await this.$axios({
        url: `${this.$apiUrl}common/interface`,
        method: 'post',
        data: data
      })

      var list = res.data.data.list
      this.total = res.data.data.total
      if (list.length > 0) {
        this.tableData = list
      }
    },
    getStdState (state) {
      const val = Number(state)
      if (val == 1) {
        return '现行'
      } else if (val == 2) {
        return '未实施'
      } else if (val == 3) {
        return '作废'
      } else {
        return ''
      }
    },
    computeIndex (index) {
      return (index + 1) + (this.PageIndex - 1) * this.PageSize
    },
    handleRowClick (row) {
      console.log(row)
      if(this.$route.query.type=='NL'){
        window.open('/orgnlldbg?id=' + row.PKID)
      }else{
        window.open('/orgldbg?id=' + row.PKID)
      }
    },
    handleClose () {
      this.dialogVisible = false
      this.dialogcont = ""
    },
    getElementsType (row) {
      let arr = []
      if (row.ElementsType1) {
        arr.push(row.ElementsType1)
      }
      if (row.ElementsType2) {
        arr.push(row.ElementsType2)
      }
      if (row.ElementsType3) {
        arr.push(row.ElementsType3)
      }
      if (row.ElementsType4) {
        arr.push(row.ElementsType4)
      }
      return arr.join(',')
    }
  }
};
</script>
<style lang="less" scoped>
.content {
  width: 100%;
  height: 1000px;
  padding: 20px 50px;
}

.pager {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
}

/deep/ .el-table__header tr {
  background: none !important;
}

/deep/ .el-table th.el-table__cell {
  background-color: transparent !important;
  color: #cbe8ff;
  font-size: 24px;
}

/deep/ .el-table th.el-table__cell > .cell {
  line-height: 48px !important;
}
// /deep/ .el-table .cell{
//     font-size: 18px;
// }
/deep/ .el-table__header tr {
  background-color: transparent;
}

/deep/ .el-table,
.el-table__expanded-cell {
  color: #fff;
  background-color: transparent;
}

/deep/ .el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border-color: rgb(128, 128, 128);
}

/deep/ .el-table tr {
  background-color: transparent !important;
  padding: 0 10px;
  box-sizing: border-box;
}

/deep/ .el-table--enable-row-transition .el-table__body td,
.el-table .cell {
  background-color: transparent;
}

/deep/ .el-table::before {
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0px;
  background: transparent;
}

/deep/.el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: #155488 !important;
  cursor: pointer;
}
/deep/ .el-table__header .cell {
  text-align: center;
}
.tab-ul {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tab-li {
  width: 200px;
  height: 50px;
  color: #fff;
  font-size: 24px;
  background: #61b2c5;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  cursor: pointer;
}
.tab-li:last-child {
  margin-right: 0;
}
.tab-li.active {
  font-weight: bold;
}
</style>
