<template>
  <div>
    <Headers :title="title" :show="false" :returnShow="true"></Headers>
    <div class="content">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column type="index" label="序号" align="center" width="100">
          <template slot-scope="scope">
            <div>{{ computeIndex(scope.$index) }}</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="ProType"
          label="产业类型"
          align="center"
          show-overflow-tooltip
          tooltip-effect="dark"
        ></el-table-column>
        <el-table-column
          prop="CoName"
          label="企业名称"
          align="center"
          show-overflow-tooltip
          tooltip-effect="dark"
        ></el-table-column>
      </el-table>
      <div class="pager">
        <el-pagination
          background
          layout="prev, pager, next"
          :current-page.sync="PageIndex"
          :total="total"
          @current-change="changepage"
          :page-size="PageSize"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import Headers from '@/components/headers';

export default {
  name: 'tpList',
  components: {
    Headers,
  },
  data() {
    return {
      dialogVisible: false,
      dialogcont: '',
      PageIndex: 1,
      PageSize: 16,
      total: 0,
      code: 'listThirdOrg',
      tableData: [],
      type: '',
      title: '温州市服装产业链图谱',
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    dateFormat(data) {
      return data.NotifyDate
        ? this.$moment(data.NotifyDate).format('YYYY-MM-DD')
        : '';
    },
    changepage(e) {
      this.PageIndex = e;
      this.init();
    },
    async init() {
      let data = {
        pageNum: this.PageIndex,
        pageSize: this.PageSize,
        code: this.$route.query.type ? 'listCompanyPro2' : 'listCompanyPro',
        protype: this.$route.query.type || '',
      };
      const res = await this.$axios({
        url: `${this.$apiUrl}common/interface`,
        method: 'post',
        data: data,
      });

      var list = res.data.data.list;
      this.total = res.data.data.total;
      if (list.length > 0) {
        this.tableData = list;
      }
    },
    computeIndex(index) {
      return index + 1 + (this.PageIndex - 1) * this.PageSize;
    },
  },
};
</script>
<style lang="less" scoped>
.content {
  width: 100%;
  height: 1000px;
  padding: 20px 50px;
}

.pager {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
}

/deep/ .el-table__header tr {
  background: none !important;
}

/deep/ .el-table th.el-table__cell {
  background-color: transparent !important;
  color: #cbe8ff;
  font-size: 24px;
}

/deep/ .el-table th.el-table__cell > .cell {
  line-height: 48px !important;
}
// /deep/ .el-table .cell{
//     font-size: 18px;
// }
/deep/ .el-table__header tr {
  background-color: transparent;
}

/deep/ .el-table,
.el-table__expanded-cell {
  color: #fff;
  background-color: transparent;
}

/deep/ .el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border-color: rgb(128, 128, 128);
}

/deep/ .el-table tr {
  background-color: transparent !important;
  padding: 0 10px;
  box-sizing: border-box;
}

/deep/ .el-table--enable-row-transition .el-table__body td,
.el-table .cell {
  background-color: transparent;
}

/deep/ .el-table::before {
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0px;
  background: transparent;
}

/deep/.el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: #155488 !important;
  cursor: pointer;
}
/deep/ .el-table__header .cell {
  text-align: center;
}
.tab-ul {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tab-li {
  width: 200px;
  height: 50px;
  color: #fff;
  font-size: 24px;
  background: #61b2c5;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  cursor: pointer;
}
.tab-li:last-child {
  margin-right: 0;
}
.tab-li.active {
  font-weight: bold;
}
</style>
