<template>
  <div>
    <Headers :title="topTitle" :show="false" :returnShow="true"></Headers>
    <div class="content">
      <ul class="tab-ul">
        <li class="tab-li" v-for="(item,index) in list" :key="index" :class="{'active':tabindex==index}" @click="tabChange(index)">{{item.value}}</li>
      </ul>
      <el-table :data="tableData" style="width: 100%" @row-click="handleRowClick">
        <el-table-column type="index" label="序号" align="center" width="100"></el-table-column>
        <el-table-column prop="StdNO" label="标准号" align="left" width="380" show-overflow-tooltip tooltip-effect="dark"></el-table-column>
        <el-table-column prop="" label="标准名称" align="left" show-overflow-tooltip tooltip-effect="dark">
          <template slot-scope="scope">
            <span>{{scope.row.CnName || scope.row.EnName}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="StdStatus" label="标准状态" align="center" width="200">
          <template slot-scope="scope">
            <span>{{getStdState(scope.row.StdStatus)}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="PubDate" label="发布日期" align="center" width="200"></el-table-column>
        <el-table-column prop="ActDate" label="实施日期" align="center" width="200"></el-table-column>
      </el-table>
      <div class="pager">
        <el-pagination background layout="prev, pager, next" :current-page.sync="PageIndex" :total="total" @current-change="changepage" :page-size="PageSize">
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import Headers from "@/components/headers"

export default {
  name: "Catalog",
  components: {
    Headers
  },
  data () {
    return {
      dialogVisible: false,
      dialogcont: "",
      PageIndex: 1,
      PageSize: 15,
      total: 100,
      code: "getStdListByType",
      rate: 'RED',
      tableData: [],
      list: [
        { key: 'GJ', value: '国际标准' },
        { key: 'GW', value: '国外标准' },
        { key: 'GB', value: '国家标准' },
        { key: 'HY', value: '行业标准' },
        { key: 'DF', value: '地方标准' },
        { key: 'TT', value: '团体标准' },
        // { key: 'QY', value: '企业标准' },
      ],
      tabindex: 0,
    }
  },
  computed: {
    topTitle () {
      return '温州市服装标准化创新服务平台'
    }
  },
  async mounted () {
    const type = this.$route.query.type || ""
    if (type) {
      let index = this.list.findIndex(p => p.key == type)
      this.tabindex = index < 0 ? 0 : index
    }

    await this.init()
  },
  methods: {
    dateFormat (data) {
      return data.NotifyDate ? this.$moment(data.NotifyDate).format('YYYY-MM-DD') : ''
    },
    changepage (e) {
      this.PageIndex = e
      this.init()
    },
    async init () {
      const res = await this.$axios({
        url: `${this.$apiUrl}common/interface`,
        method: 'post',
        data: { pageNum: this.PageIndex, pageSize: this.PageSize, code: this.code, type: this.list[this.tabindex].key }
      })

      var list = res.data.data.list || []
      this.total = res.data.data.total
      this.tableData = list
    },
    tabChange (index) {
      this.tabindex = index
      this.PageIndex = 1
      this.init()
    },
    getStdState (state) {
      const val = Number(state)
      if (val == 1) {
        return '现行'
      } else if (val == 2) {
        return '未实施'
      } else if (val == 3) {
        return '作废'
      } else {
        return ''
      }
    },
    computeIndex (index) {
      return (index + 1) + (this.PageIndex - 1) * this.PageSize
    },
    handleRowClick (row) {
      window.open('/catalogView?id=' + row.CatalogID)
    },
    handleClose () {
      this.dialogVisible = false
      this.dialogcont = ""
    },
    getElementsType (row) {
      let arr = []
      if (row.ElementsType1) {
        arr.push(row.ElementsType1)
      }
      if (row.ElementsType2) {
        arr.push(row.ElementsType2)
      }
      if (row.ElementsType3) {
        arr.push(row.ElementsType3)
      }
      if (row.ElementsType4) {
        arr.push(row.ElementsType4)
      }
      return arr.join(',')
    }
  }
};
</script>
<style lang="less" scoped>
.content {
  width: 100%;
  height: 1000px;
  padding: 20px 50px;
}

.pager {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
}

/deep/ .el-table__header tr {
  background: none !important;
}

/deep/ .el-table th.el-table__cell {
  background-color: transparent !important;
  color: #cbe8ff;
  font-size: 24px;
}

/deep/ .el-table th.el-table__cell > .cell {
  line-height: 48px !important;
}
// /deep/ .el-table .cell{
//     font-size: 18px;
// }
/deep/ .el-table__header tr {
  background-color: transparent;
}

/deep/ .el-table,
.el-table__expanded-cell {
  color: #fff;
  background-color: transparent;
}

/deep/ .el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border-color: rgb(128, 128, 128);
}

/deep/ .el-table tr {
  background-color: transparent !important;
  padding: 0 10px;
  box-sizing: border-box;
}

/deep/ .el-table--enable-row-transition .el-table__body td,
.el-table .cell {
  background-color: transparent;
}

/deep/ .el-table::before {
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0px;
  background: transparent;
}

/deep/.el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: #155488 !important;
  cursor: pointer;
}
/deep/ .el-table__header .cell {
  text-align: center;
}
.tab-ul {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tab-li {
  width: 200px;
  height: 50px;
  color: #fff;
  font-size: 24px;
  background: #61b2c5;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  cursor: pointer;
}
.tab-li:last-child {
  margin-right: 0;
}
.tab-li.active {
  font-weight: bold;
}
</style>
