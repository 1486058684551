<template>
  <div>
    <Headers title="温州市服装标准化创新服务平台" :show="false" :returnShow="true"></Headers>
    <div class="content">
      <div class="ld-1">
        <div class="ld-1-l" v-if="stdInfo">
          <h3 class="ld-bt">基本信息</h3>
          <div class="ld-1-l1">
            <!-- <img :src="stdInfo.PdfUrl" alt="" class="ld-1-report"> -->
            <!-- <div class="ld-1-2">
              <div class="dp-wu" v-if="!stdInfo.PdfUrl">暂无文本</div>
              <iframe class="dp-iframe" v-if="stdInfo.PdfUrl" :src="stdInfo.PdfUrl" frameborder="0"></iframe>
            </div> -->
            <div class="ld-1-2">
              <!-- <div class="Recommendability">
                推荐性
              </div> -->
              <ul class="ld-2-list">
                <li>标准号：<span class="ld-2-listnr">{{stdInfo.StdNO}}</span></li>
                <span class="ld-2-listline"><img src="../../../src/assets/imgs/ld-2-listline.png" alt=""></span>
                <li>标准名：<span class="ld-2-listnr">{{stdInfo.CnName || stdInfo.EnName}}</span></li>
                <span class="ld-2-listline"><img src="../../../src/assets/imgs/ld-2-listline.png" alt=""></span>
                <li>发布日期：<span class="ld-2-listnr">{{stdInfo.PubDate}}</span></li>
                <span class="ld-2-listline"><img src="../../../src/assets/imgs/ld-2-listline.png" alt=""></span>
                <li>实施日期：<span class="ld-2-listnr">{{stdInfo.ActDate}}</span></li>
                <span class="ld-2-listline"><img src="../../../src/assets/imgs/ld-2-listline.png" alt=""></span>
                <li>替代标准：<span class="ld-2-listnr">{{stdInfo.InsteadStd}}</span></li>
              </ul>
            </div>
            <div class="ld-1-3">
              <div class="ld-1-3bt">
                主要变化
              </div>
              <div class="ld-1-3p">
                <p v-html="stdInfo.ForewordChange"></p>
              </div>
            </div>
          </div>
        </div>
        <div class="ld-1-l ld-1-m">
          <h3 class="ld-bt ld-bt2">标准词云</h3>
          <div class="details-blc details-blc-1m">
            <div id="cloud" style="width:100%;height:100%"></div>
            <!-- <div id="cloud" _echarts_instance_="ec_1625653650092" style="-webkit-tap-highlight-color: transparent; user-select: none; background: transparent;width:100%;height:100%">
                <div style="position: relative;width:100%;height:100%; overflow: hidden;cursor: default;">
                    <canvas width="1920" height="1089" data-zr-dom-id="zr_0" style="position: absolute; left: 0px; top: 0px; width:100%;height:100%; user-select: none; -webkit-tap-highlight-color: rgba(0, 0, 0, 0);"></canvas>
                </div>
            </div> -->
            <!-- <img src="../../../src/assets/imgs/fontcloud.png" alt="" class="ld-1-mnr"> -->
          </div>

        </div>
        <div class="ld-1-l ld-1-m" v-if="stdInfo">
          <h3 class="ld-bt ld-bt2">类似标准</h3>
          <div class="details-blc details-blc-1m">
            <ul class="ld-1-r">
               <!-- @click="openu('/details?id='+item.SimilarStdNo)" -->
              <li v-for="(item,index) in lslist" :key="index">
                <img src="../../../src/assets/imgs/icon1.png" alt="" width="7px" height="7px">
                {{item.SimilarStdNo}}{{item.SimilarStdName}}
              </li>
              <!-- <li>
                <img src="../../../src/assets/imgs/icon1.png" alt="" width="7px" height="7px">
                GB 4789.15-2016 食品安全国家标准 食品微生物学检验 霉菌和酵母计数
              </li>
              <li>
                <img src="../../../src/assets/imgs/icon1.png" alt="" width="7px" height="7px">
                ISO 21527-1-2008 食品和动物饲料的微生物学 酵母菌和霉菌计数的并行法 第1部分:水活性＞0.95产品中的菌落计数技术
              </li>
              <li>
                <img src="../../../src/assets/imgs/icon1.png" alt="" width="7px" height="7px">
                QB/T 4572-2021 酵母β-葡聚糖
              </li>
              <li>
                <img src="../../../src/assets/imgs/icon1.png" alt="" width="7px" height="7px">
                GB/T 32099-2015 酵母产品分类导则
              </li>
              <li>
                <img src="../../../src/assets/imgs/icon1.png" alt="" width="7px" height="7px">
                GB 4789.15-2016 食品安全国家标准 食品微生物学检验 霉菌和酵母计数
              </li>
              <li>
                <img src="../../../src/assets/imgs/icon1.png" alt="" width="7px" height="7px">
                ISO 21527-1-2008 食品和动物饲料的微生物学 酵母菌和霉菌计数的并行法 第1部分:水活性＞0.95产品中的菌落计数技术
              </li> -->
            </ul>
          </div>
        </div>
      </div>
      <div class="details-b">
        <div class="details-bl">
          <h3 class="ld-bt">智联关系</h3>
          <div class="details-blc">
            <div class="details-blci">
              <img src="../../assets/img/radar-yy.png" alt="">
            </div>
            <div class="chart1" id="chart1"></div>
            <!-- <img src="../../assets/imgs/zlgx.png" alt=""> -->
          </div>
        </div>
        <div class="details-br">
          <h3 class="ld-bt">标准应用</h3>
          <div class="details-brc">
            <div class="config" style="margin-bottom:10px">
              <Tablescroll :config="tableConfig" ref="tableConfig" refName="tableConfig"></Tablescroll>
            </div>
            <div class="config">
              <Tablescroll :config="tableConfig1" ref="tableConfig1" refName="tableConfig1"></Tablescroll>
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-dialog custom-class="tcbg" :visible.sync="dialogVisible" :before-close="handleClose" top="5vh" width="80%">
      <div class="tcimg">
        <img :src="imgsrc" alt="" />
      </div>
    </el-dialog>
  </div>
</template>
<script>

// import { world } from "../utils/worldZH"
import router from "../../router"
import Headers from "@/components/headers"
import Tablescroll from "@/components/tablescroll"
import "echarts-wordcloud/dist/echarts-wordcloud";
import "echarts-wordcloud/dist/echarts-wordcloud.min.js";
// import Histogram from "@/components/histogram";
// import Echartsline from "@/components/line";
// import Radar from "@/components/radar";
export default {
  name: "ldindex",
  components: {
    Headers,
    Tablescroll,
    // Histogram,
    // Echartsline,
    // Radar,
  },
  data () {
    return {
      imgsrc: "",
      dialogVisible: false,
      plist: [],
      tableConfig: {
        headerBGC: "none",
        oddRowBGC: "none",
        evenRowBGC: "none",
        rowNum: 5,
        header: ["企业名称", "地区", "执行标准"],
        columnWidth: [280, 200],
        align: ["left", "left", "left"],
        data: [],
      },
      tableConfig1: {
        headerBGC: "none",
        oddRowBGC: "none",
        evenRowBGC: "none",
        rowNum: 5,
        header: ["机构", "检测产品", "检验能力"],
        columnWidth: [280, 150],
        align: ["left", "left", "left"],
        data: [],
      },
      stdInfo: null, // 关联标准信息,
      lslist:[]
    }
  },
  mounted () {
    document.title = '温州市服装标准化创新服务平台'
    // 标准信息
    this.getStdInfo()
  },
  methods: {
    async getcahrt(){
      const res = await this.$axios({
        url: `${this.$apiUrl}common/interface`,
        method: 'post',
        data: { code: 'getSLStdDetailsCYByStdNo', stdNo: this.stdInfo.StdNO,single: true, }
      })
      var myChart = this.$echarts.init(document.getElementById('cloud'))

      var data = [];
      // 'rgb(' + [
      //       Math.round(Math.random() * 160),
      //       Math.round(Math.random() * 160),
      //       Math.round(Math.random() * 160)
      //     ].join(',') + ')'
      var i=10;
      var arr=res.data.data.KeyWord.split(';')
      arr.forEach(e=>{
        // var color='rgb(' + [
        //                 Math.round(Math.random() * 160),
        //                 Math.round(Math.random() * 160),
        //                 Math.round(Math.random() * 160)
        //             ].join(',') + ')'
        data.push({
            name: e,
            value: i+=1,
            textStyle: {
                color:"#fff"
            },
        })
      })
      data.sort(function (a, b) {
          return b.value - a.value;
      })
      // var maskImage = new Image();

      var option = {
          series: [{
              type: 'wordCloud',
              // clickable: true,
               sizeRange: [20, 50],
              // rotationRange: [-90, 90],
              // rotationStep: 45,
              // gridSize: 2,
              // shape: 'pentagon',
              // width: 600,
              // height: 400,
              // drawOutOfBound: true,
              // maskImage: maskImage,
              
              data: data
          }]
      };

      // maskImage.onload = function () {
      //     option.series[0].maskImage
          myChart.setOption(option,true);
      // }
      // maskImage.src = require('../../assets/img/logo2.png');
    },
    async getchart(){
      const res = await this.$axios({
        url: `${this.$apiUrl}common/interface`,
        method: 'post',
        data: { code: 'listStdCitedQuote', stdNo: this.stdInfo.StdNO,single: true, }
      })
      
      var data = [
        { 'symbolSize': '200', 'symbol': 'image://'+this.$apiUrl+'radar-center.png', 'label': { 'fontSize': '18',color:'#fff' }, 'name': this.stdInfo.InsteadStd, 'x': '300', 'y': ' 300' },
        { 'symbolSize': '100', 'symbol': 'image://'+this.$apiUrl+'radar-orange.png', 'label': { color:'#fff' }, 'x': '100', 'y': '200' },
        { 'symbolSize': '100', 'symbol': 'image://'+this.$apiUrl+'radar-orange.png', 'label': { color:'#fff' }, 'x': '200', 'y': '150' },
        { 'symbolSize': '100', 'symbol': 'image://'+this.$apiUrl+'radar-orange.png', 'label': { color:'#fff' }, 'x': '400', 'y': '150' },
        { 'symbolSize': '100', 'symbol': 'image://'+this.$apiUrl+'radar-orange.png', 'label': { color:'#fff' }, 'x': '500', 'y': '200' },
        { 'symbolSize': '100', 'symbol': 'image://'+this.$apiUrl+'radar-green.png', 'label': { color:'#fff' }, 'x': '100', 'y': '400' },
        { 'symbolSize': '100', 'symbol': 'image://'+this.$apiUrl+'radar-green.png', 'label': { color:'#fff' },  'x': '200', 'y': '450' },
        { 'symbolSize': '100', 'symbol': 'image://'+this.$apiUrl+'radar-green.png', 'label': { color:'#fff' },  'x': '400', 'y': '450' },
        { 'symbolSize': '100', 'symbol': 'image://'+this.$apiUrl+'radar-green.png', 'label': { color:'#fff' },  'x': '500', 'y': '400' }
      ]
      
      var links =[
        {'source': '0', 'target': '1','lineStyle': { 'width': '5', 'curveness': '0.2', 'color': '#39c5ffcc'}},
        // {'source': res.data.data[0],'target': this.stdInfo.StdNO,'lineStyle':{ 'width': '5','curveness':'0.2','color': '#39c5ffcc'}},
        // {'source': 'GB/T 30948-2014','target': 'DL/T 548','lineStyle':{ 'width': '5','curveness':'0.2','color': '#39c5ffcc'}},
        // {'source': 'GB/T 30948-2014','target': 'DL/T 572','lineStyle':{ 'width': '5','curveness':'0.2','color': '#39c5ffcc'}},
        // {'source': 'GB/T 30948-2014','target': 'DL/T 573','lineStyle':{ 'width': '5','curveness':'0.2','color': '#39c5ffcc'}},
        // {'source': 'GB/T 51033-2014','target': 'GB/T 30948-2014','lineStyle':{ 'width': '5','curveness':'0.2','color': '#39c5ffcc'}},
        // {'source': 'SL/T 246-2019','target': 'GB/T 30948-2014','lineStyle':{ 'width': '5','curveness':'0.2','color': '#39c5ffcc'}},
        // {'source': 'SL 310-2019','target': 'GB/T 30948-2014','lineStyle':{ 'width': '5','curveness':'0.2','color': '#39c5ffcc'}},
        // {'source': 'DB33/T 2248-2020','target': 'GB/T 30948-2014','lineStyle':{ 'width': '5','curveness':'0.2','color': '#39c5ffcc'}},
        // {'source': 'SL/T 246-2019','target': 'DL/T 572','lineStyle':{ 'width': '5','curveness':'0.2','color': '#39c5ffcc'}},
        // {'source': 'DB33/T 2248-2020','target': 'DL/T 572','lineStyle':{ 'width': '5','curveness':'0.2','color': '#39c5ffcc'}},
        // {'source': 'DB33/T 2248-2020','target': 'DL/T 573','lineStyle':{ 'width': '5','curveness':'0.2','color': '#39c5ffcc'}}
      ];
      var qudata=[];
      qudata.push(data[0])
      var yyindexdata=1;
      var byyindexdata=5;
      // StdCited 被引用
      // StdQuote 引用
      var json=[]
      if(res.data.data.StdCited){
        var arr=res.data.data.StdCited.split(';').filter(function(value,index,self){
            return self.indexOf(value) === index;
        });
        arr.splice(0,4).forEach(e=>{
            json.push({
              type:'被引用',
              StdNo:e,
            })
        })
      }
      if(res.data.data.StdQuote){
        var arr1=res.data.data.StdQuote.split(';').filter(function(value,index,self){
            return self.indexOf(value) === index;
        });
        arr1.splice(0,4).forEach(e=>{
            json.push({
              type:'引用',
              StdNo:e,
            })
        })
      }
      json.forEach(e=>{
        if(e.type=='引用'){
          var de=data[yyindexdata];
          de.name=e.StdNo
          qudata.push(de)
          yyindexdata++
        }else{
          var d=data[byyindexdata];
          d.name=e.StdNo
          qudata.push(d)
          byyindexdata++
        }
        if(e.type=='引用'){
          links.push({
            'source': e.StdNo,
            'target': this.stdInfo.InsteadStd,
            'lineStyle':{ 'width': '5','curveness':'0.2','color': '#39c5ffcc'}
          })
        }else{
          links.push({
            'source': this.stdInfo.InsteadStd,
            'target': e.StdNo,
            'lineStyle':{ 'width': '5','curveness':'0.2','color': '#39c5ffcc'}
          })
        }
      })
      // qudata.forEach(e=>{
      //   console.log(e)
      //   if(!e.name){
      //     e.name='无'
      //     e.show=true
      //   }
      // })
      var myChart = this.$echarts.init(document.getElementById('chart1'))
      var option = {
          title: {
              show: false,
              // text: 'Basic Graph'
          },
          tooltip: {
              show: false
          },
          // animationDurationUpdate: 1500,
          // animationEasingUpdate: 'quinticInOut',
          series: [
              {
                  type: 'graph',
                  layout: 'none',
                  symbol: "circle",
                  symbolSize: 50,
                  zoom:0.9,
                  // roam: true,
                  label: {
                      show: true
                  },
                  edgeSymbol: ['circle', 'arrow'],
                  edgeSymbolSize: [4, 10],
                  // edgeLabel: {
                  //     fontSize: 20
                  // },
                  data: [...qudata],
                  links: [...links],
                  // lineStyle: {
                  //     opacity: 0.9,
                  //     width: 2,
                  //     curveness: 0
                  // }
              }
          ]
      }
      myChart.setOption(option,true)
    },
    async getStdInfo () {
      const res = await this.$axios({
        url: `${this.$apiUrl}common/interface`,
        method: 'post',
        data: { code: 'getSLStdDetailsByType', single: true, id: this.$route.query.id||"",std:this.$route.query.std||"" }
      })

      if (res && res.data) {
        this.stdInfo = res.data.data
        this.getQY()
        this.getJG()
        this.getchart()
        this.getcahrt()
        this.getLS()
      }
    },
    async getQY () {
      const res = await this.$axios({
        url: `${this.$apiUrl}common/interface`,
        method: 'post',
        data: { code: 'listCompanyDis', std:this.stdInfo.StdNO,pageSize:50,pageNum:1 }
      })

      if (res && res.data) {
        this.tableConfig.data = []
        res.data.data.list.forEach((item) => {
          this.tableConfig.data.push([
            item["COMPANY_NAME"],
            item["AREA_NAME"],
            item["StdNO"],
          ])
        })
        this.$set(this.tableConfig, "data", this.tableConfig.data)
        this.$refs["tableConfig"].updateR()
      }
    },
    async getJG () {
      const res = await this.$axios({
        url: `${this.$apiUrl}common/interface`,
        method: 'post',
        data: { code: 'listThirdOrg', std:this.stdInfo.InsteadStd,pageSize:50,pageNum:1 }
      })

      if (res && res.data) {
        this.tableConfig1.data = []
        res.data.data.list.forEach((item) => {
          this.tableConfig1.data.push([
            item["OrgName"],
            item["Category"],
            item["ProductName"],
          ])
        })
        this.$set(this.tableConfig1, "data", this.tableConfig1.data)
        this.$refs["tableConfig1"].updateR()
      }
    },
    async getLS () {
      const res = await this.$axios({
        url: `${this.$apiUrl}common/interface`,
        method: 'post',
        data: { code: 'listStdSimilar', stdNo:this.stdInfo.InsteadStd, }
      })

      if (res && res.data) {
        console.log(res)
        this.lslist=res.data.data.splice(0,10)
      }
    },
    newopen (url) {
      let imgUrl = router.resolve({ path: "./jpgimage", query: { url: url } })
      window.open(imgUrl.href)
    },
    open (url) {
      this.imgsrc = require("../../assets/img/" + url + ".png")
      this.dialogVisible = true
    },
    openu (url) {
      window.open(url)
    },
    handleClose () {
      this.dialogVisible = false
    },
    getRawHtml (data) {
      if (data) {
        return data.replace(/[\s]/g, '<br/>')
      }
      return ''
    }
  },
};
</script>
<style lang="less" scoped>
.details-b {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.details-br {
  width: 750px;
}

.details-bl {
  width: 1100px;
}

.details-blc {
  width: 100%;
  height: 470px;
  background: url(../../assets/imgs/ld-3-bg.png) no-repeat center center;
  background-size: 100% 100%;
}

.details-brc {
  width: 100%;
  background: url(../../assets/imgs/ld-3-bg.png) no-repeat center center;
  background-size: 100% 100%;
  padding: 20px;
}
.details-blci{
  width: 100%;
  padding: 30px 30px 10px 30px;
}
.config {
  width: 100%;
  height: 210px;
}

.content {
  width: 100%;
  height: 1000px;
  display: flex;
  // justify-content: space-between;
  flex-direction: column;
  padding: 0 20px;
}

.dp-title {
  color: #fff;
  font-size: 25px;
  text-decoration: none;
  display: flex;
  align-items: center;
  font-weight: bold;
  line-height: 40px;
  padding: 0 40px;
}

.large-charttitle.active {
  color: rgb(0, 239, 255);
}

.dp-l {
  width: 30%;
}

.dp-c {
  width: 35%;
}

.dp-r {
  width: 32%;
}

.dpc {
  width: 100%;
  height: calc(100% - 40px);
  padding: 10px 20px;
}

.dp-ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.dp-li {
  color: #fff;
  width: 24%;
  padding: 10px;
  background: url(../../assets/img/byc2.png) no-repeat center center;
  background-size: 100% 100%;
  margin-bottom: 10px;
  cursor: pointer;
}

.dp-li span {
  display: block;
  text-align: center;
  margin: 5px 0;
  font-size: 18px;
}

.dp-li span:last-child {
  color: #00ffde;
  font-weight: bold;
}

.dp-iframe {
  width: 100%;
  height: 575px;
}

/deep/ .row-item {
  width: 100%;
  background-image: url(../../assets/img/by7.png);
  background-size: 100% 90%;
  background-repeat: no-repeat;
  padding: 0 10px;
  box-sizing: border-box;
}

/deep/ .header-item {
  // text-align: center;
  color: #00deff;
}

.tcimg {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tcimg img {
  width: 100%;
  height: 100%;
}

.hrefs {
  height: 25px;
  width: 25px;
  background-image: url(../../assets/img/arrow.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  z-index: 2;
  position: relative;
  margin: 5px 10px;
  cursor: pointer;
}

.dpl1 {
  width: 100%;
  height: 225px;
  margin-bottom: 10px;
  background-image: url(../../assets/img/by1.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  box-sizing: border-box;
}

.dpl2 {
  width: 100%;
  height: 440px;
  margin-bottom: 10px;
  background-image: url(../../assets/imgs/radar10.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  box-sizing: border-box;
}

.dpl3 {
  width: 100%;
  height: 285px;
  background-image: url(../../assets/imgs/radar12.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  box-sizing: border-box;
}

.dpr {
  width: 100%;
  height: 960px;
  background-image: url(../../assets/imgs/radar16.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  box-sizing: border-box;
}

.dplc {
  width: 100%;
  height: calc(100% - 40px);
  padding: 10px 20px;
}

.dplc1 {
  background: url(../../assets/imgs/radar1.png) no-repeat center center;
}

.dplc2 {
  display: flex;
  justify-content: space-between;
}

.dprc {
  width: 100%;
  height: calc(100% - 40px);
  padding: 10px 20px;
}

.dplc-s {
  display: flex;
  justify-content: space-between;
  height: 48px;
  line-height: 48px;
  padding: 0 10px;
  margin-bottom: 10px;
}

.dplc-sl {
  color: #fff;
}

.dplc-sr {
  color: #fff;
}

.dplc-sl em {
  color: #00deff;
  font-weight: bold;
  font-size: 22px;
  font-style: normal;
}

.dplc-sr em {
  color: #00deff;
  font-weight: bold;
  font-size: 22px;
  font-style: normal;
}

.radar4 {
  width: 100%;
}

.radar11 {
  width: 100%;
  margin-bottom: 10px;
}

.dplc-l {
  width: 48%;
  height: 100%;
}

.dplc-lt {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  background: url(../../assets/imgs/radar13.png) no-repeat center center;
  background-size: 100% 100%;
  color: #ffde00;
  font-size: 20px;
  font-weight: bold;
}

.dplc-b {
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  padding: 0 15px;
  background: url(../../assets/imgs/radar14.png) no-repeat center center;
  background-size: 100% 100%;
  box-sizing: border-box;
}

.dplc-b1 {
  height: 60px;
}

.dplc-bl {
  width: 140px;
  color: #fff;
}

.dplc-bl img {
  width: 10px;
  height: 10px;
  margin-right: 5px;
}

.dplc-br {
  width: calc(100% - 140px);
  color: #00deff;
  font-size: 24px;
}

.byc-s1 {
  width: 100%;
  height: 200px;
  background-image: url(../../assets/img/byc-s1.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
  padding: 25px 20px 20px 20px;
}

.byc-s1 span {
  display: block;
}

.byc-s1 span:first-child {
  color: #fff;
  font-size: 21px;
}

.byc-s1 span:first-child em {
  color: #ffac45;
  font-weight: bold;
  font-style: normal;
  font-size: 21px;
}

.byc-s1 span:last-child {
  color: #fff;
  font-size: 21px;
}

.byc-s1 span:last-child em {
  color: #00ffde;
  font-weight: bold;
  font-style: normal;
  font-size: 21px;
}

.dpr-title {
  width: 100%;
  height: 40px;
  background-image: url(../../assets/imgs/radar17.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
}

.dpr1 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.dpr1-l {
  width: 48%;
  height: 112px;
  position: relative;
  background: url(../../assets/imgs/radar5.png) no-repeat center center;
}

.dpr1-r {
  width: 48%;
  height: 112px;
  background: url(../../assets/imgs/radar6.png) no-repeat center center;
}

.dpr1-l span {
  position: absolute;
  bottom: 10px;
  right: 15px;
  color: #fffc00;
  width: 120px;
  height: 70px;
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: bold;
}

.dpr2 {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.dpr2-l {
  width: 24%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.dpr2-lt {
  color: #fff;
}

.dpr2-lb {
  color: #00deff;
  font-size: 20px;
  font-weight: bold;
}

.dpr3 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 10px 0;
}

.dpr3-l {
  width: 48%;
  height: 90px;
  background: url(../../assets/img/byl-s4.png) no-repeat center center;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.dpr3-s {
  width: 100px;
  display: flex;
  flex-direction: column;
  // align-items: center;
  margin-left: 10px;
}

.dpr3-sl {
  color: #fff;
}

.dpr3-sr {
  color: #00deff;
  font-size: 20px;
  font-weight: bold;
}

.dpr3-r {
  width: 48%;
  height: 60px;
  background: url(../../assets/img/nbg.png) no-repeat center center;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 10px;
}

.dpr4 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
}

.dpr4-l {
  width: 180px;
}

.dpr4-lc {
  width: 100%;
  height: 70px;
  background: url(../../assets/img/byl-s4.png) no-repeat center center;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}


.ld-bt {
  background-image: url(../../../src/assets/imgs/bztp-bt-bg.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: #fff;
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 10px;
}

.ld-1 {
  display: flex;
  height: 420px;
  justify-content: space-between;
}

.ld-1-l {
  // border: 1px solid #00deff;
  height: 400px;
  width: 1100px;
}

.ld-1-l1 {
  display: flex;
  justify-content: space-between;
  height: 340px;
}

.ld-1-report {
  display: block;
  width: 240px;
  height: 340px;
}

.ld-1-2 {
  background-image: url(../../../src/assets/imgs/bztp-nr-bg1.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 269px;
  height: 100%;
  position: relative;
}

.Recommendability {
  background-image: url(../../../src/assets/imgs/bztp-1-tjx.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 101px;
  height: 33px;
  font-size: 20px;
  color: rgb(255, 211, 3);
  font-weight: bold;
  line-height: 33px;
  text-align: center;
  position: absolute;
  right: 10px;
  top: 10px;
}

.ld-2-list {
  margin-left: 20px;
  margin-top: 16px;
}

.ld-2-list li {
  font-size: 18px;
  line-height: 28px;
  color: white;
}

.ld-2-listnr {
  color: #00ffde;
}

.ld-1-3 {
  background-image: url(../../../src/assets/imgs/ld-3-bg.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 810px;
  height: 100%;
}

.ld-1-3bt {
  background-image: url(../../../src/assets/imgs/ld-3-bt.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: 40px;
  text-align: center;
  color: #fff;
  font-weight: bold;
  font-size: 22px;
  margin: 0 auto;
  line-height: 40px;
  color: #00fff0;
}
.ld-1-3p{
  width: 100%;
  height: calc(100% - 40px);
  padding: 10px;
}
.ld-1-3p p{
  width: 100%;
  height: 100%;
  color: #00fff0;
  font-size: 20px;
  line-height: 32px;
  text-align: justify;
  white-space: pre-wrap;
  overflow: hidden;
  overflow-y: auto;
  padding-right: 10px;
}

.ld-1-3list {
  // margin-top: 10px;
  margin-left: 20px;
}

.ld-1-3list li {
  color: #fff;
  font-size: 16px;
  line-height: 30px;
  margin-bottom: 10px;
}

.ld-1-m {
  width: 360px;
}

.ld-bt2 {
  width: 360px;
}

.details-blc-1m {
  width: 100%;
  height: 340px;
}
.ld-1-mnr {
  width: 100%;
  height: 100%;
}
.ld-1-r {
  padding: 10px;
}
.ld-1-r li {
  display: block;
  color: #fff;
  font-size: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 20px;
}
.ld-1-r img {
  margin-right: 10px;
}
.dp-wu {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  font-size: 40px;
  color: #ccc;
}
.chart1{
  width: 100%;
  height: calc(100% - 60px);
}
</style>
