<template>
  <div>
    <Headers
      title="温州市服装标准化创新服务平台"
      :show="false"
      :returnShow="true"
    ></Headers>
    <div class="content">
      <el-table
        :data="tableData"
        style="width: 100%"
        @row-click="handleRowClick"
      >
        <el-table-column
          prop="COMPANY_NAME"
          label="企业名称"
          align="left"
          width="240"
          show-overflow-tooltip
          tooltip-effect="dark"
        ></el-table-column>
        <el-table-column
          prop="StdNO"
          label="执行标准号"
          align="left"
          show-overflow-tooltip
          tooltip-effect="dark"
        ></el-table-column>
        <el-table-column
          prop="CnName"
          label="执行标准名称"
          align="left"
          show-overflow-tooltip
          tooltip-effect="dark"
        ></el-table-column>
        <el-table-column
          prop="RelationStdNO"
          label="涉联标准号"
          align="left"
          show-overflow-tooltip
          tooltip-effect="dark"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.RelationStdNO }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="RelationStdStatus" label="涉联标准状态" align="center">
          <template slot-scope="scope">
            <span>{{getStdState(scope.row.RelationStdStatus)}}</span>
          </template>
        </el-table-column> -->
        <el-table-column
          prop="RelationStdAct"
          width="150"
          label="实施日期"
          align="center"
        >
          <template slot-scope="scope">
            <span :style="getStdStlye(scope.row)">{{
              scope.row.RelationStdAct
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop=""
          label="涉变数据"
          width="450"
          show-overflow-tooltip
          tooltip-effect="dark"
        >
          <template slot-scope="scope">
            <span>{{ getElementsType(scope.row) }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="InputDate"
          :formatter="dateFormat"
          label="推送时间"
          align="center"
          width="140"
          show-overflow-tooltip
          tooltip-effect="dark"
        ></el-table-column>
        <el-table-column
          prop=""
          label="对策建议"
          width="130"
          align="center"
          show-overflow-tooltip
          tooltip-effect="dark"
        >
          <template>
            <span class="dp-see"></span>
            <!-- <span>{{getAdvice(scope.row)}}</span> -->
          </template>
        </el-table-column>
      </el-table>
      <div class="pager">
        <el-pagination
          background
          layout="prev, pager, next"
          :current-page.sync="PageIndex"
          :total="total"
          @current-change="changepage"
          :page-size="PageSize"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import Headers from "@/components/headers";

export default {
  name: "Company",
  components: {
    Headers,
  },
  data() {
    return {
      dialogVisible: false,
      dialogcont: "",
      PageIndex: 1,
      PageSize: 12,
      total: 100,
      code: "listCompany",
      tableData: [],
      type: "",
    };
  },
  mounted() {
    this.type = this.$route.query.type || "";
    this.init();
  },
  methods: {
    dateFormat(data) {
      return data.InputDate
        ? this.$moment(data.InputDate).format("YYYY-MM-DD")
        : "";
    },
    changepage(e) {
      this.PageIndex = e;
      this.init();
    },
    async init() {
      let data = {
        pageNum: this.PageIndex,
        pageSize: this.PageSize,
        code: this.code,
        type:this.type
      };
      if (this.$route.query.std) {
        data.std = this.$route.query.std;
      }
      if (this.$route.query.rstd) {
        data.rstd = this.$route.query.rstd;
      }
      const res = await this.$axios({
        url: `${this.$apiUrl}common/interface`,
        method: "post",
        data: data,
      });

      var list = res.data.data.list;
      this.total = res.data.data.total;
      if (list.length > 0) {
        this.tableData = list;
      }
    },
    getStdStlye(row) {
      switch (this.getStdState(row.RelationStdStatus)) {
        case "现行":
          return "color:#6cff3f";
        case "未实施":
          return "color:#ffe64f";
        case "作废":
          return "color:#da5164";
      }
    },
    getStdState(state) {
      const val = Number(state);
      if (val == 1) {
        return "现行";
      } else if (val == 2) {
        return "未实施";
      } else if (val == 3) {
        return "作废";
      } else {
        return "";
      }
    },
    computeIndex(index) {
      return index + 1 + (this.PageIndex - 1) * this.PageSize;
    },
    handleRowClick(row) {
      console.log(row);
      window.open("/ldbg?id=" + row.PKID);
    },
    handleClose() {
      this.dialogVisible = false;
      this.dialogcont = "";
    },
    getElementsType(row) {
      let arr = [];
      if (row.ElementsType1) {
        arr.push(row.ElementsType1);
      }
      if (row.ElementsType2) {
        arr.push(row.ElementsType2);
      }
      if (row.ElementsType3) {
        arr.push(row.ElementsType3);
      }
      if (row.ElementsType4) {
        arr.push(row.ElementsType4);
      }
      return arr.join(",");
    },
    getAdvice(row) {
      let arr = [];
      if (row.AdviceInfo1) {
        arr.push(row.AdviceInfo1);
      }
      if (row.AdviceInfo2) {
        arr.push(row.AdviceInfo2);
      }
      if (row.AdviceInfo3) {
        arr.push(row.AdviceInfo3);
      }
      if (row.AdviceInfo4) {
        arr.push(row.AdviceInfo4);
      }
      return arr.join(",");
    },
  },
};
</script>
<style lang="less" scoped>
.content {
  width: 100%;
  height: 1000px;
  padding: 20px 50px;
}

.pager {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
}

/deep/ .el-table__header tr {
  background: none !important;
}

/deep/ .el-table th.el-table__cell {
  background-color: transparent !important;
  color: #cbe8ff;
  font-size: 24px;
}

/deep/ .el-table th.el-table__cell > .cell {
  line-height: 48px !important;
}
// /deep/ .el-table .cell{
//     font-size: 18px;
// }
/deep/ .el-table__header tr {
  background-color: transparent;
}

/deep/ .el-table,
.el-table__expanded-cell {
  color: #fff;
  background-color: transparent;
}

/deep/ .el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border-color: rgb(128, 128, 128);
}

/deep/ .el-table tr {
  background-color: transparent !important;
  padding: 0 10px;
  box-sizing: border-box;
}

/deep/ .el-table--enable-row-transition .el-table__body td,
.el-table .cell {
  background-color: transparent;
}

/deep/ .el-table::before {
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0px;
  background: transparent;
}

/deep/.el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: #155488 !important;
  cursor: pointer;
}
/deep/ .el-table__header .cell {
  text-align: center;
}
.tab-ul {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tab-li {
  width: 200px;
  height: 50px;
  color: #fff;
  font-size: 24px;
  background: #61b2c5;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  cursor: pointer;
}
.tab-li:last-child {
  margin-right: 0;
}
.tab-li.active {
  font-weight: bold;
}
</style>
